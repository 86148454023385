body {
    margin: 0;
    font-family: "Akkurat", -apple-system, BlinkMacSystemFont, Segoe UI,
    Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
    font-size: 16px;
    line-height: 1.5;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

@font-face {
    font-family: "Akkurat";
    src: url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Regular.woff2")
    format("woff2"),
    url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Regular.woff")
    format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Akkurat";
    src: url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Bold.woff2")
    format("woff2"),
    url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Bold.woff")
    format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Akkurat";
    src: url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-BoldItalic.woff2")
    format("woff2"),
    url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-BoldItalic.woff")
    format("woff");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Akkurat";
    src: url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Italic.woff2")
    format("woff2"),
    url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Italic.woff")
    format("woff");
    font-weight: 400;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Akkurat Mono";
    src: url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratMonoLLWeb-Regular.woff2")
    format("woff2"),
    url("https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratMonoLLWeb-Regular.woff")
    format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
